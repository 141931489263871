import React from 'react';
import classes from "./Slot.module.scss";
import {useDispatch} from "react-redux";
import {setSelectSlot} from "../../../redux/reducers/bookingReducer";
import {useNavigate, useParams} from "react-router-dom";
import ticket from "../Ticket/Ticket";
import Translate from "../Translate/Translate";

const Slot = ({slot, typeExcursion}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const callbackSelectSlot = (e) => {
        e.preventDefault()
        dispatch(setSelectSlot(slot))
        navigate(`/${params.lang}/${params.group}/cart`)
    }
    console.log(typeExcursion)
    return (
        <div className={classes.slot}>

            <div className={classes.time}>
                <svg width="13" height="13" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z" fill="#000"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9 3.20001C9.55228 3.20001 10 3.64773 10 4.20001V8.38198L12.6472 9.70558C13.1412 9.95257 13.3414 10.5532 13.0944 11.0472C12.8474 11.5412 12.2468 11.7414 11.7528 11.4944L8.55279 9.89444C8.214 9.72505 8 9.37878 8 9.00001V4.20001C8 3.64773 8.44772 3.20001 9 3.20001Z" fill="#000"/>
                </svg>

                {slot.time.start.time} - {slot.time.end.time}
            </div>

            <div className={classes.buy}>

                <div className={classes.payment}>

                    <span className={classes.price}>
                        {slot.type == "ticket" &&
                            <>{slot.price} UAH</>
                        }
                        {slot.type == "group" && typeExcursion == "small_group" &&
                            <>{slot.price} UAH</>
                        }
                        {slot.type == "group" && typeExcursion == "big_group" &&
                            <>{slot.price * slot.count.from} UAH</>
                        }
                    </span>

                    <span className={classes.price_description}>
                        {slot.type == "ticket" &&
                            <span>
                                <Translate text={"for_the_ticket"}/>
                            </span>
                        }
                        {slot.type == "group" && typeExcursion == "small_group" &&
                            <span>
                                <Translate text={"for_the_group"}/>
                            </span>
                        }
                        {slot.type == "group" && typeExcursion == "big_group" &&
                            <span>
                                {/*<Translate text={"for_one_person"}/>*/}
                                <Translate text={"for_the_group"}/>
                            </span>
                        }
                    </span>

                </div>


                <button className={classes.button} onClick={callbackSelectSlot} disabled={!slot.available}>
                    {slot.type == "ticket" &&
                        <span>{slot.tickets}</span>
                    }
                    {slot.type == "group" &&
                        <span>
                            <Translate text={"select"}/>
                        </span>
                    }
                </button>

            </div>
        </div>
    );
};

export default Slot;