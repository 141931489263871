import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getLanguages,
    getOptions,
    getDiscounts,
    setDataOptionsBooking,
    updateBookingPrice,
    applyOptionsGroup,
    applyLanguageGroup,
    applyCountGroup,
    applyDiscountGroup,
    checkPromocodeGroup,
    clearTicketGroupData,
    checkCertificateGroup, clearDiscountGroup, checkPromocode, clearPromocode
} from "../../../redux/reducers/bookingReducer";
import classes from "./FormGroup.module.scss";
import Error from "../../UI/Error/Error";
import {useNavigate, useParams} from "react-router-dom";
import Multiple from "../../Multiple/Multiple";
import SelectItem from "../../UI/SelectItem/SelectItem";
import Modal from "../../UI/Modal/Modal";
import Translate from "../../UI/Translate/Translate";
import ticket from "../../UI/Ticket/Ticket";

const FormGroup = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    
    const translates = useSelector(state => state.booking.translates)
    const group = useSelector(state => state.booking.group)
    const ticketGroup = useSelector(state => state.booking.ticketGroup)
    const languages = useSelector(state => state.booking.languages)
    const options = useSelector(state => state.booking.options)
    const discounts = useSelector(state => state.booking.discounts)
    const selectSlot = useSelector(state => state.booking.selectSlot)
    const bookingPrice = useSelector(state => state.booking.bookingPrice)
    const selectTypeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const selectDate = useSelector(state => state.booking.selectDate)

    const refPromocode = useRef(null)
    const refCertificate = useRef(null)

    const [currentExcursion, changeCurrentExcursion] = useState(null)
    const [count, setCount] = useState(null)
    const [typeDiscount, changeTypeDiscount] = useState(false)
    const [optionsList, changeOptionsList] = useState([])
    const [confirmState, changeConfirmState] = useState(false)
    const [certificateError, changeCertificateError] = useState(null)
    const [countError, changeCountError] = useState(null)
    const [languageError, changeLanguageError] = useState(null)
    const [discountError, changeDiscountError] = useState(null)
    const [promocodeError, changePromocodeError] = useState(null)
    const [confirmError, changeConfirmError] = useState(null)
    const [modalDiscount, changeModalDiscount] = useState(false)

    const classNameCertificate = []
    if(certificateError) classNameCertificate.push("error")

    const classNamePromocode = []
    if(promocodeError) classNamePromocode.push("error")
    if(
        ticketGroup.discount &&
        ticketGroup.discount.id &&
        ticketGroup.discount.code
    ) {
        if(ticketGroup.discount.type == "certificate"){
            classNameCertificate.push("success")
        }
        if(ticketGroup.discount.type == "promocode") {
            classNamePromocode.push("success")
        }
    }

    const onChangeCount = (e) => {
        let value = Number(e.currentTarget.value);
        if (value) {
            const arrPercent = currentExcursion.pricePerPerson.filter(item => value >= item.persons)
            let count = {
                value: value,
                percent: arrPercent.length != 0 ? arrPercent.pop().percent : 0
            }
            dispatch(applyCountGroup(count))
            changeCountError(null)
        } else {
            changeCountError(translates.choose_the_number_of_people[params?.lang ? params.lang : 'default'])
        }
    }
    const onChangeLanguage = (e) => {
        let value = e.currentTarget.value;
        if (value) {
            let selectLang = languages.find(lang => lang.id == value)
            changeLanguageError(null)
            dispatch(applyLanguageGroup(selectLang))
        } else {
            changeLanguageError("Виберіть мову проведення")
        }
    }
    const onChangeTypeDiscount = (e) => {
        let value = e.currentTarget.value;
        changeTypeDiscount(value)

        dispatch(applyDiscountGroup(null))

        changeCertificateError(null)
        changePromocodeError(null)
    }
    const onChangeDiscount = (e) => {
        let value = Number(e.currentTarget.value);

        if (value) {
            let selectDiscount = discounts.find(discount => discount.id == value)
            changeDiscountError(null)
            dispatch(applyDiscountGroup({
                type: "discount",
                id: selectDiscount.id,
                percent: selectDiscount.percent
            }))
        } else {
            changeDiscountError(translates.сhoose_a_discount[params?.lang ? params.lang : 'default'])
        }
    }
    const onChangeCertificate = (e) => {
        e.preventDefault()
        dispatch(clearDiscountGroup())
        if (certificateError) {
            changeCertificateError(null)
        }
    }
    const checkCertificate = (e) => {
        e.preventDefault()
        const code = Number(refCertificate.current.value);
        const obj = {
            excursion_id: selectTypeExcursion.id,
            code: code,
            date: {...selectDate},
            count: 0,
        }
        dispatch(checkCertificateGroup(obj))
        changeCertificateError(null)
    }
    const onChangePromocode = (e) => {
        e.preventDefault()
        dispatch(clearDiscountGroup())
        if (promocodeError) {
            changePromocodeError(null)
        }
    }
    const checkPromocode = (e) => {
        e.preventDefault()
        const code = Number(refPromocode.current.value);
        const obj = {
            excursion_id: selectTypeExcursion.id,
            code: code,
            date: {...selectDate},
            count: 0,
        }
        dispatch(checkPromocodeGroup(obj))
        changePromocodeError(null)
    }
    const onChangeConfirm = (e) => {

        let value = e.currentTarget.checked
        changeConfirmState(value)
        if (confirmError) {
            changeConfirmError(null)
        }
    }
    const onChangeOptions = (selected) => {
        let prevArr = optionsList;
        let newArr = prevArr.map(item => {
            if (item.value == selected) {
                item.selected = item.selected == false ? true : false
                return item
            } else {
                return item
            }
        })
        changeOptionsList(newArr)
        dispatch(applyOptionsGroup(
            newArr.filter(option => option.selected == true).map(item => {
                return ({
                    id: item.value,
                    name: item.name,
                    price: item.price
                })
            })
        ))
    }
    const onSubmitForm = (e) => {
        e.preventDefault()
        let form = e.currentTarget

        if (ticketGroup.count == null) {
            changeCountError(translates.choose_the_number_of_people[params?.lang ? params.lang : 'default'])
        } else {
            changeCountError(null)
        }

        if (ticketGroup.language == null) {
            changeLanguageError("Виберіть мову проведення")
        } else {
            changeLanguageError(null)
        }
        //
        // if (ticketGroup.discount && ticketGroup.discount.type == "certificate" && ticketGroup.discount.code == "") {
        //     changeCertificateError("Недійсний сертифікат")
        // }
        //
        // if (ticketGroup.discount && ticketGroup.discount.type == "certificate" && ticketGroup.discount.code) {
        //     changeCertificateError(null)
        // }

        // if (ticketGroup.discount && ticketGroup.discount.type == "promocode" && ticketGroup.discount.code == "") {
        //     changePromocodeError("Недійсний промокод")
        // }
        //
        // if (ticketGroup.discount && ticketGroup.discount.type == "promocode" && ticketGroup.discount.code) {
        //     changePromocodeError(null)
        // }

        if (typeDiscount == "discount" && ticketGroup.discount == null) {
            changeDiscountError(translates.сhoose_a_discount[params?.lang ? params.lang : 'default'])
        } else {
            changeDiscountError(null)
        }
        if (typeDiscount == "certificate"){
            if (ticketGroup.discount == null) {
                if (refCertificate.current.value) {
                    changeCertificateError('Застосуйте сертифікат')
                } else {
                    changeCertificateError(translates.enter_the_certificate_code[params?.lang ? params.lang : 'default'])
                }
            } else {
                if(ticketGroup.discount.code == ""){
                    changeCertificateError("Недійсний сертифікат")
                } else {
                    changeCertificateError(null)
                }
            }
        } else {
            changeCertificateError(null)
        }

        if (typeDiscount == "promocode"){
            if (ticketGroup.discount == null) {
                if (refPromocode.current.value) {
                    changePromocodeError('Застосуйте промокод')
                } else {
                    changePromocodeError('Введіть промокод')
                }
            } else {
                if(ticketGroup.discount.code == ""){
                    changePromocodeError("Недійсний промокод")
                } else {
                    changePromocodeError(null)
                }
            }
        } else {
            changePromocodeError(null)
        }

        if (form.elements?.confirm?.checked == false) {
            changeConfirmError('Підтвердіть')
        } else {
            changeConfirmError(null)
        }


        if (
            (ticketGroup.discount && ticketGroup.discount.code == "") ||
            (ticketGroup.count == null) ||
            (ticketGroup.language == null) ||
            (typeDiscount == "discount" && ticketGroup.discount == null) ||
            (typeDiscount == "certificate" && ticketGroup.discount == null) ||
            (typeDiscount == "promocode" && ticketGroup.discount == null) ||
            (form.elements?.confirm?.checked == false)
        ) {
            return false
        } else {
            navigate(`/${params.lang}/${params.group}/checkout`)
        }

    }

    useEffect(() => {
        if (languages == null) {
            const id = selectTypeExcursion.id
            dispatch(getLanguages(id))
        }
        if(languages){
            dispatch(applyLanguageGroup(languages[0]))
        }
    }, [languages])
    useEffect(() => {
        if (options == null) {
            const id = selectTypeExcursion.id
            dispatch(getOptions(id))
        }
    }, [options])
    useEffect(() => {
        if (options) {
            changeOptionsList(
                options.map(option => {
                    let nameOption = ""
                    switch (params?.lang){
                        case "ua":
                            nameOption = option.name.find(lang => lang.id == 1).text
                            break

                        case "ru":
                            nameOption = option.name.find(lang => lang.id == 2).text
                            break

                        case "en":
                            nameOption = option.name.find(lang => lang.id == 3).text
                            break

                        default:
                            nameOption = option.name.find(lang => lang.default_language).text
                            break
                    }
                    return (
                        {
                            name: `${nameOption} (+${option.price} UAH)`,
                            value: option.id,
                            price: option.price,
                            selected: false
                        }
                    )
                })
            )
        }
    }, [options])
    useEffect(() => {
        if (discounts == null) {
            const id = selectTypeExcursion.id
            dispatch(getDiscounts(id))
        }
    }, [discounts])
    useEffect(() => {
        if (selectSlot) {
            let arr = []
            for (let i = selectSlot.count.from; i <= selectSlot.count.to; i++) {
                arr.push(i)
            }
            setCount(arr)
        }
    }, [selectSlot])
    useEffect(() => {
        if (selectSlot) {
            dispatch(updateBookingPrice())
        }
    }, [selectSlot])
    useEffect(() => {
        if (selectSlot) {
            if (ticketGroup.discount) {
                changeTypeDiscount(ticketGroup.discount.type)
                if (typeDiscount == "certificate" && ticketGroup.discount.code == "") {
                    changeCertificateError('Невірний код сертифікату')
                }
                if (typeDiscount == "promocode" && ticketGroup.discount.code == "") {
                    changePromocodeError('Невірний промокод')
                }
            }
            if (ticketGroup.options) {
                changeOptionsList(
                    options.map(option => {
                        let nameOption = ""
                        switch (params?.lang){
                            case "ua":
                                nameOption = option.name.find(lang => lang.id == 1).text
                                break

                            case "ru":
                                nameOption = option.name.find(lang => lang.id == 2).text
                                break

                            case "en":
                                nameOption = option.name.find(lang => lang.id == 3).text
                                break

                            default:
                                nameOption = option.name.find(lang => lang.default_language).text
                                break
                        }
                        return (
                            {
                                name: `${nameOption} (+${option.price} UAH)`,
                                value: option.id,
                                price: option.price,
                                selected: ticketGroup.options.find(item => item.id == option.id) ? true : false
                            }
                        )
                    })
                )
            }

            dispatch(updateBookingPrice())
        }
    }, [ticketGroup])
    useEffect(() => {
        if (selectTypeExcursion.id) {
            changeCurrentExcursion(group.find(excursion => excursion.id == selectTypeExcursion.id))
        }
    }, [selectTypeExcursion])

    return (
        <div>
            <form className={classes.form} onSubmit={onSubmitForm}>

                <div>

                    <div>
                        <h3> <Translate text={'number_of_people'}/>:* </h3>

                        {count &&
                            <>
                                <select
                                    name="count"
                                    id="count"
                                    onChange={onChangeCount}
                                    className={(countError) ? "error" : ""}
                                    value={ticketGroup.count ? ticketGroup.count.value : false}
                                >
                                    <option value={false}><Translate text={'choose_the_number_of_people'}/></option>
                                    {count.map(number => {
                                        return (
                                            <option key={number} value={number}>
                                                {number}
                                            </option>
                                        )
                                    })}
                                </select>
                                {countError && typeof countError == "string" &&
                                    <Error>{countError}</Error>
                                }
                            </>
                        }

                    </div>

                    <div>
                        <h3> <Translate text={'the_language_of_the_excursion'}/> * </h3>

                        {languages && languages.length > 1 &&
                            <>
                                <select
                                    name="language"
                                    id="language"
                                    onChange={onChangeLanguage}
                                    className={(languageError) ? "error" : ""}
                                    value={ticketGroup.language ? ticketGroup.language.id : false}
                                >
                                    <option value={false}>
                                        <Translate text={"select_the_language"} />
                                    </option>
                                    {languages.map(lang => {
                                        return (
                                            <option key={lang.name} value={lang.id}>{lang.name}</option>
                                        )
                                    })}
                                </select>
                                {languageError && typeof languageError == "string" &&
                                    <Error>{languageError}</Error>
                                }
                            </>
                        }
                        {languages && languages.length == 1 && ticketGroup.language &&
                            <SelectItem>
                                {ticketGroup.language.name}
                            </SelectItem>
                        }
                    </div>
                    {options && options.length != 0 &&
                        <div>
                            <h3>
                                <Translate text={'ordering_additional_services'} />
                            </h3>
                            <Multiple
                                options={optionsList}
                                placeholder={translates.select_options[params?.lang ? params.lang : 'default']}
                                onChange={onChangeOptions}
                            />
                        </div>
                    }

                </div>

                <div>

                    <div>
                        <h3>
                            <Translate text={'discount_certificate_promo_code'} />
                        </h3>

                        <select
                            name="typeDiscount"
                            id="typeDiscount"
                            onChange={onChangeTypeDiscount}
                            value={typeDiscount}
                        >
                            <option value={false}><Translate text={'standard_price'} /></option>
                            <option value="discount"><Translate text={'discount'} /></option>
                            <option value="certificate"><Translate text={'certificate'} /></option>
                            <option value="promocode"><Translate text={'promocode'} /></option>
                        </select>
                    </div>

                    <div>
                        {typeDiscount == "discount" && discounts &&
                            <>
                                <select
                                    name="discount"
                                    id="discount"
                                    onChange={onChangeDiscount}
                                    className={(discountError) ? "error" : ""}
                                    value={(ticketGroup.discount && ticketGroup.discount.type == "discount") ? ticketGroup.discount.id : false}
                                >
                                    <option value={false}>
                                        <Translate text={'сhoose_a_discount'} />
                                    </option>
                                    {discounts.map(discount => {
                                        let nameDiscount = ""
                                        switch (params?.lang){
                                            case "ua":
                                                nameDiscount = discount.name.find(lang => lang.id == 1).text
                                                break

                                            case "ru":
                                                nameDiscount = discount.name.find(lang => lang.id == 2).text
                                                break

                                            case "en":
                                                nameDiscount = discount.name.find(lang => lang.id == 3).text
                                                break

                                            default:
                                                nameDiscount = discount.name.find(lang => lang.default_language).text
                                                break
                                        }
                                        return (
                                            <option key={discount.name.find(lang => lang.default_language).text}
                                                    value={discount.id}>
                                                {nameDiscount}
                                            </option>
                                        )
                                    })}
                                </select>
                                {discountError && typeof discountError == "string" &&
                                    <Error>{discountError}</Error>
                                }
                            </>
                        }
                        {typeDiscount == "certificate" &&
                            <div style={{position: "relative"}}>
                                <input
                                    ref={refCertificate}
                                    name="certificate"
                                    type="number"
                                    onChange={onChangeCertificate}
                                    placeholder={translates.certificate[params?.lang ? params.lang : 'default']}
                                    className={classNameCertificate.join(" ")}
                                />
                                {certificateError && typeof certificateError == "string" &&
                                    <Error>{certificateError}</Error>
                                }
                                <a
                                    href="#"
                                    onClick={checkCertificate}
                                    style={{
                                        display: "block",
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px"
                                    }}
                                >
                                    <Translate text={'apply'} />
                                </a>

                            </div>
                        }
                        {typeDiscount == "promocode" &&
                            <div style={{position: "relative"}}>
                                <input
                                    ref={refPromocode}
                                    name="promocode"
                                    type="number"
                                    placeholder={translates.promocode[params?.lang ? params.lang : 'default']}
                                    onChange={onChangePromocode}
                                    className={classNamePromocode}
                                />
                                {promocodeError && typeof promocodeError == "string" &&
                                    <Error>{promocodeError}</Error>
                                }
                                <a
                                    href="#"
                                    onClick={checkPromocode}
                                    style={{
                                        display: "block",
                                        position: "absolute",
                                        top: "calc( 50% - 15px )",
                                        right: "10px"
                                    }}
                                >
                                    Застосувати
                                </a>
                            </div>

                        }
                    </div>

                    <div>
                        <a
                            href="#"
                            style={{textAlign: "right", display: "block"}}
                            onClick={(e) => {
                                e.preventDefault()
                                changeModalDiscount(true)
                            }}
                        >
                            <Translate text={'how_can_i_use_the_discounts'} />
                        </a>
                    </div>

                </div>

                <div className={classes.accept} style={{marginTop: "40px"}}>

                    <input
                        id="confirm"
                        name="confirm"
                        type="checkbox"
                        className={(confirmError) ? "error" : ""}
                        onChange={onChangeConfirm}
                        checked={confirmState}
                    />

                    <label htmlFor="confirm">
                        <Translate text={'i_confirm'}/>
                    </label>
                </div>

                <div className={classes.footer}>

                    {bookingPrice &&
                        <span className={classes.price}>
                            Сума: <span> {bookingPrice} UAH </span>
                        </span>
                    }

                    <button disabled={confirmState ? false : true} style={{maxWidth: "345px", width: "100%"}}>
                        <Translate text={'checkout'}/>
                    </button>

                </div>

            </form>

            <Modal active={modalDiscount} title={translates.how_can_i_use_the_discounts[params?.lang ? params.lang : 'default']}>

                <div>
                    <p style={{textAlign: "center"}}>
                        <Translate text={"discount_description"}/>
                    </p>
                </div>

                <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                        style={{
                            maxWidth: "345px",
                            width: "100%",
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            changeModalDiscount(false)
                        }}
                    >
                        <Translate text={'close'}/>
                    </button>
                </div>

            </Modal>
        </div>
    );
};

export default FormGroup;