import React from 'react';
import classes from "./SelectItem.module.scss";

const SelectItem = ({children}) => {
    return (
        <div className={classes.item}>
            {children}
        </div>
    );
};

export default SelectItem;