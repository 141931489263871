import BookingApi from "../../api/BookingApi";

const SET_TRANSLATES = "SET_TRANSLATES"
const SET_EXCURSIONS = "SET_EXCURSIONS"
const SET_BRANCH = "SET_BRANCH"
const SET_SELECT_DATE = "SET_SELECT_DATE"
const SET_SLOTS = "SET_SLOTS"
const SET_SELECT_SLOT = "SET_SELECT_SLOT"
const SET_SELECT_TYPE_EXCURSION = "SET_SELECT_TYPE_EXCURSION"
const SET_DATA_CLIENT = "SET_DATA_CLIENT"
const SET_LANGUAGES = "SET_LANGUAGES"
const SET_ALL_LANGUAGES = "SET_ALL_LANGUAGES"
const SET_OPTIONS = "SET_OPTIONS"
const SET_DISCOUNTS = "SET_DISCOUNTS"
const SET_DATA_OPTIONS_BOOKING = "SET_DATA_OPTIONS_BOOKING"
const ADD_TICKET = "ADD_TICKET"
const DELETE_TICKET = "DELETE_TICKET"
const APPLY_DISCOUNT = "APPLY_DISCOUNT"
const APPLY_PROMOCODE = "APPLY_PROMOCODE"
const CLEAR_PROMOCODE = "CLEAR_PROMOCODE"
const APPLY_CERTIFICATE = "APPLY_CERTIFICATE"
const CLEAR_CERTIFICATE = "CLEAR_CERTIFICATE"
const APPLY_OPTIONS = "APPLY_OPTIONS"
const APPLY_OPTIONS_GROUP = "APPLY_OPTIONS_GROUP"
const APPLY_LANGUAGE_GROUP = "APPLY_LANGUAGE_GROUP"
const APPLY_COUNT_GROUP = "APPLY_COUNT_GROUP"
const APPLY_DISCOUNT_GROUP = "APPLY_DISCOUNT_GROUP"
const SET_BASE_PRICE_TICKET = "SET_BASE_PRICE_TICKET"
const UPDATE_BOOKING_PRICE = "UPDATE_BOOKING_PRICE"
const CLEAR_TICKETS = "CLEAR_TICKETS"
const CLEAR_TICKET_GROUP_DATA = "CLEAR_TICKET_GROUP_DATA"
const CLEAR_DISCOUNT_GROUP = "CLEAR_DISCOUNT_GROUP"
const SET_CALENDAR = "SET_CALENDAR"


const defaultState = {
    translates: false,
    allLanguages: null,
    group: null,
    branch: null,
    slots: null,
    calendar: null,
    selectTypeExcursion: null,
    selectDate: null,
    selectSlot: null,
    dataClient: null,
    dataOptionsBooking: null,
    languages: null,
    options: null,
    discounts: null,
    tickets: [],
    ticketGroup: {
        count: null,
        options: null,
        language: null,
        discount: null
    },
    bookingPrice: null,
}

export const bookingsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EXCURSIONS:
            return {
                ...state,
                group: action.bookings,
            }
        case SET_TRANSLATES:
            return {
                ...state,
                translates: action.translates,
            }
        case SET_BRANCH:
            return {
                ...state,
                branch: action.branch,
            }
        case SET_CALENDAR:
            return {
                ...state,
                calendar: action.calendar,
            }
        case SET_SELECT_DATE:

            return {
                ...state,
                selectDate: action.date
            }
        case SET_SLOTS:
            return {
                ...state,
                slots: action.slots,
            }
        case SET_SELECT_SLOT:
            return {
                ...state,
                selectSlot: action.slot,
            }
        case SET_SELECT_TYPE_EXCURSION:
            return {
                ...state,
                selectTypeExcursion: action.typeExcursion
            }
        case SET_DATA_CLIENT:
            return {
                ...state,
                dataClient: action.data
            }
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.languages
            }
        case SET_ALL_LANGUAGES:
            return {
                ...state,
                allLanguages: action.languages
            }
        case SET_OPTIONS:
            return {
                ...state,
                options: action.options
            }
        case SET_DISCOUNTS:
            return {
                ...state,
                discounts: action.discounts
            }
        case SET_DATA_OPTIONS_BOOKING:
            return {
                ...state,
                dataOptionsBooking: action.data
            }
        case ADD_TICKET:
            return {
                ...state,
                tickets: [...state.tickets, action.ticket]
            }
        case DELETE_TICKET:
            return {
                ...state,
                tickets: [...state.tickets].filter(ticket => ticket.id != action.ticket_id)
            }
        case APPLY_DISCOUNT:
            return {
                ...state,
                tickets: [...state.tickets].map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.discount = {
                            type: "discount",
                            discountId: action.discount_id,
                            percent: action.percent,
                        }
                        ticket.price = ticket.basePrice - ((ticket.basePrice / 100) * action.percent)
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case APPLY_PROMOCODE:
            return {
                ...state,
                tickets: [...state.tickets].map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.discount = {
                            type: "promocode",
                            promocodeId: action.promocode_id,
                            percent: action.percent,
                            code: action.code
                        }
                        ticket.price = ticket.basePrice - ((ticket.basePrice / 100) * action.percent)
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case CLEAR_PROMOCODE:
            return {
                ...state,
                tickets: [...state.tickets].map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.discount = false
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case APPLY_CERTIFICATE:
            return {
                ...state,
                tickets: [...state.tickets].map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.discount = {
                            type: "certificate",
                            certificateId: action.certificate_id,
                            percent: action.percent,
                            code: action.code
                        }
                        ticket.price = ticket.basePrice - ((ticket.basePrice / 100) * action.percent)
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case CLEAR_CERTIFICATE:
            return {
                ...state,
                tickets: [...state.tickets].map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.discount = false
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case APPLY_OPTIONS:
            return {
                ...state,
                tickets: [...state.tickets].map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.options = action.options
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case APPLY_OPTIONS_GROUP:
            return {
                ...state,
                ticketGroup: {
                    ...state.ticketGroup,
                    options: action.options
                }
            }
        case APPLY_LANGUAGE_GROUP:
            return {
                ...state,
                ticketGroup: {
                    ...state.ticketGroup,
                    language: action.language,
                }
            }
        case APPLY_COUNT_GROUP:
            return {
                ...state,
                ticketGroup: {
                    ...state.ticketGroup,
                    count: action.count,
                }
            }
        case APPLY_DISCOUNT_GROUP:
            return {
                ...state,
                ticketGroup: {
                    ...state.ticketGroup,
                    discount: action.discount
                }
            }
        case SET_BASE_PRICE_TICKET:
            return {
                ...state,
                tickets: state.tickets.map(ticket => {
                    if (ticket.id == action.ticket_id) {
                        ticket.discount = false
                        ticket.price = ticket.basePrice
                        return ticket
                    } else {
                        return ticket
                    }
                })
            }
        case UPDATE_BOOKING_PRICE:
            let newPrice = 0;
            if (state.selectTypeExcursion.key == "ticket") {
                let ticketsArr = [...state.tickets]
                ticketsArr.forEach(ticket => {
                    newPrice += ticket.price
                    if (ticket.options && ticket.options.length != 0) {
                        ticket.options.forEach(option => {
                            newPrice += option.price
                        })
                    }

                })
                return {
                    ...state,
                    bookingPrice: (newPrice) ? Number(newPrice.toFixed(0)) : null
                }
            } else {
                let newPrice = 0
                // newPrice += state.selectSlot.price

                let ticketGroup = state.ticketGroup

                if (ticketGroup.count) {

                    let pricePerPerson = state.group.find(excursion => excursion.id == state.selectTypeExcursion.id).pricePerPerson

                    if (state.selectTypeExcursion.key == "small_group"){
                        let basePrice = state.selectSlot.price
                        newPrice = basePrice
                    }
                    if (state.selectTypeExcursion.key == "big_group"){
                        for (let i = 1; i <= ticketGroup.count.value; i++) {
                            let currentDiscountPerson = pricePerPerson.filter(item => i >= item.persons)
                            currentDiscountPerson = currentDiscountPerson.pop()

                            let basePrice = state.selectSlot.price

                            if (currentDiscountPerson) {
                                newPrice = newPrice + (basePrice - ((basePrice / 100) * currentDiscountPerson.percent))
                            } else {
                                newPrice = newPrice + basePrice
                            }
                        }
                    }
                }

                if (ticketGroup.discount) {
                    newPrice = newPrice - ((newPrice / 100) * ticketGroup.discount.percent)
                }

                if (ticketGroup.language) {
                    newPrice += ticketGroup.language.price
                }

                if (ticketGroup.options) {
                    ticketGroup.options.forEach(option => {
                        newPrice += option.price
                    })
                }

                return {
                    ...state,
                    bookingPrice: newPrice == 0 ? null : Number(newPrice.toFixed(0))
                }
            }
        case CLEAR_TICKETS:
            return {
                ...state,
                tickets: [],
            }
        case CLEAR_TICKET_GROUP_DATA:
            return {
                ...state,
                ticketGroup: {
                    count: null,
                    options: null,
                    language: null,
                    discount: null
                },
                bookingPrice: null,
            }
        case CLEAR_DISCOUNT_GROUP:
            return {
                ...state,
                ticketGroup: {
                    ...state.ticketGroup,
                    discount: null
                },
            }

        default:
            return state;
    }
}

export const setExcursions = (bookings) => ({type: SET_EXCURSIONS, bookings});
export const setTranslates = (translates) => ({type: SET_TRANSLATES, translates});
export const setBranch = (branch) => ({type: SET_BRANCH, branch});
export const setCalendar = (calendar) => ({type: SET_CALENDAR, calendar});
export const setSelectDate = (date) => ({type: SET_SELECT_DATE, date});
export const setSlots = (slots) => ({type: SET_SLOTS, slots});
export const setSelectSlot = (slot) => ({type: SET_SELECT_SLOT, slot});
export const setSelectTypeExcursion = (typeExcursion) => ({type: SET_SELECT_TYPE_EXCURSION, typeExcursion});
export const setDataClient = (data) => ({type: SET_DATA_CLIENT, data});
export const setDataOptionsBooking = (data) => ({type: SET_DATA_OPTIONS_BOOKING, data});
export const setLanguages = (languages) => ({type: SET_LANGUAGES, languages});
export const setAllLanguages = (languages) => ({type: SET_ALL_LANGUAGES, languages});
export const setOptions = (options) => ({type: SET_OPTIONS, options});
export const setDiscounts = (discounts) => ({type: SET_DISCOUNTS, discounts});
export const addTicket = (ticket) => ({type: ADD_TICKET, ticket});
export const deleteTicket = (ticket_id) => ({type: DELETE_TICKET, ticket_id});

export const applyDiscount = (ticket_id, percent, discount_id) => ({
    type: APPLY_DISCOUNT,
    ticket_id,
    percent,
    discount_id
});
export const applyPromocode = (ticket_id, percent, promocode_id, code) => ({
    type: APPLY_PROMOCODE,
    ticket_id,
    percent,
    promocode_id,
    code
});
export const clearPromocode = (ticket_id) => ({ type: CLEAR_PROMOCODE, ticket_id });
export const applyCertificate = (ticket_id, percent, certificate_id, code) => ({
    type: APPLY_CERTIFICATE,
    ticket_id,
    percent,
    certificate_id,
    code
});
export const clearCertificate = (ticket_id) => ({ type: CLEAR_CERTIFICATE, ticket_id });
export const applyOptions = (ticket_id, options) => ({type: APPLY_OPTIONS, ticket_id, options});

export const applyOptionsGroup = (options) => ({type: APPLY_OPTIONS_GROUP, options});
export const applyLanguageGroup = (language) => ({type: APPLY_LANGUAGE_GROUP, language});
export const applyCountGroup = (count) => ({type: APPLY_COUNT_GROUP, count});
export const applyDiscountGroup = (discount) => ({type: APPLY_DISCOUNT_GROUP, discount});

export const setBasePriceTicket = (ticket_id) => ({type: SET_BASE_PRICE_TICKET, ticket_id});
export const updateBookingPrice = () => ({type: UPDATE_BOOKING_PRICE});
export const clearTickets = () => ({type: CLEAR_TICKETS})
export const clearTicketGroupData = () => ({type: CLEAR_TICKET_GROUP_DATA})
export const clearDiscountGroup = () => ({type: CLEAR_DISCOUNT_GROUP})


export const getExcursions = (data) => async (dispatch) => {
    await BookingApi.getGroupExcursions(data).then(response => {
        if (response.data && response.data.length !== 0) {
            dispatch(setExcursions(response.data.excursions))
            dispatch(setSelectTypeExcursion({
                ...response.data.excursions[0].typeExcursion,
                id: response.data.excursions[0].id
            }))
            dispatch(setBranch(response.data.branch))
        }
    })
}
export const getCalendar = (data) => async (dispatch) => {
    await BookingApi.getCalendarMonth(data).then(response => {
        console.log(response.data)
        dispatch(setCalendar(response.data))
    })
}
export const getBranch = () => async (dispatch) => {
    dispatch(setBranch({
        type: "branch",
        id: 5,
        name: [
            {
                id: 1,
                text: "Львів, Шевченка, 32",
                default_language: true,
            },
            {
                id: 2,
                text: "Львів, Шевченка, 32",
            },
            {
                id: 3,
                text: "Львів, Шевченка, 32",
            },
        ],
    }))
}
// export const getSlots = (date) => async (dispatch) => {
//     dispatch(setSlots())
// }
export const getLanguages = (id) => async (dispatch) => {
    await BookingApi.getLanguagesExcursion(id).then(response => {
        if (response.data) {
            dispatch(setLanguages(response.data))
        }
    })
}
export const getOptions = (id) => async (dispatch) => {
    await BookingApi.getOptionsExcursion(id).then(response => {
        if (response.data) {
            dispatch(setOptions(response.data))
        }
    })
}
export const getDiscounts = (id) => async (dispatch) => {
    await BookingApi.getDiscountsExcursion(id).then(response => {
        if (response.data) {
            dispatch(setDiscounts(response.data))
        }
    })
}
export const checkPromocode = (obj, ticket_id) => async (dispatch) => {
    await BookingApi.applyPromocode(obj).then(response => {
        if (response.data) {
            dispatch(applyPromocode(ticket_id, response.data.percent, response.data.id, response.data.code))
        } else {
            dispatch(applyPromocode(ticket_id, 0, "", ""))

        }
    })
}
export const checkPromocodeGroup = (obj) => async (dispatch) => {
    await BookingApi.applyPromocode(obj).then(response => {

        if (response.data) {
            dispatch(applyDiscountGroup({
                type: response.data.type,
                id: response.data.id,
                code: response.data.code,
                percent: response.data.percent
            }))
        } else {
            dispatch(applyDiscountGroup({
                type: "promocode",
                id: "",
                code: "",
                percent: 0
            }))
        }
    })
}
export const checkCertificate = (obj, ticket_id) => async (dispatch) => {
    await BookingApi.applyCertificate(obj).then(response => {
        if (response.data) {
            dispatch(applyCertificate(ticket_id, response.data.percent, response.data.id, response.data.code))
        } else {
            dispatch(applyCertificate(ticket_id, 0, "", ""))
        }
    })
}
export const checkCertificateGroup = (obj) => async (dispatch) => {
    await BookingApi.applyCertificate(obj).then(response => {
        if (response.data) {
            dispatch(applyDiscountGroup({
                type: response.data.type,
                id: response.data.id,
                code: response.data.code,
                percent: response.data.percent
            }))
        } else {
            dispatch(applyDiscountGroup({
                type: "certificate",
                id: "",
                code: "",
                percent: 0
            }))
        }
    })
}
export const addBooking = (data, ref = null) => async (dispatch) => {
    await BookingApi.addBooking(data).then(response => {
        if(response.data && ref.current){
            ref.current.click()
        }
    })
}
export const getTranslate = () => async (dispatch) => {
    await BookingApi.getTranslate().then(response => {
        dispatch(setTranslates(response.data))
    })
}
export const getAllLanguages = (id) => async (dispatch) => {
    await BookingApi.getAllLanguages(id).then(response => {
        if (response.data) {
            dispatch(setAllLanguages(response.data))
        }
    })
}