import React from 'react';
import PageFormHeader from "./PageFormHeader/PageFormHeader";
import classes from "./PageForm.module.scss";

const PageForm = ({step, backLink, children}) => {
    return (
        <div className={classes.container}>

            <PageFormHeader step={step} backLink={backLink}/>

            <div className={classes.content}>
                {children}
            </div>

        </div>
    );
};

export default PageForm;