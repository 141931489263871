import React, {useEffect, useState} from 'react';
import classes from "./PageFormHeader.module.scss";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import location from "../../../../assets/images/location.svg";
import {useSelector} from "react-redux";
import Translate from "../../../UI/Translate/Translate";

const PageFormHeader = ({step, backLink}) => {
    const params = useParams()
    const navigate = useNavigate()
    
    const translates = useSelector(state => state.booking.translates)
    const branch = useSelector(state => state.booking.branch)
    const group = useSelector(state => state.booking.group)
    const typeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const selectDate = useSelector(state => state.booking.selectDate)
    const selectSlot = useSelector(state => state.booking.selectSlot)
    
    const formatDay = {weekday: 'short', month: 'long', day: 'numeric'}
    const [date, changeDate] = useState(null)
    const [branchName, changeBranchName] = useState(null)
    const [groupName, changeGroupName] = useState(null)
    const [typeExcursionName, changeTypeExcursionName] = useState(null)

    useEffect(() => {
        if (selectSlot == null || selectDate == null || typeExcursion == null) {
            navigate(`/${params.lang}/${params.group}/booking`)
        }
    }, [])
    useEffect(() => {
        if (selectDate) {
            let objSelectDay = new Date(selectDate.year, selectDate.month - 1, selectDate.day)

            switch (params?.lang) {
                case "ua":
                    changeDate(objSelectDay.toLocaleString('uk-UA', formatDay))
                    break;

                case "ru":
                    changeDate(objSelectDay.toLocaleString('ru-UA', formatDay))
                    break;

                case "en":
                    changeDate(objSelectDay.toLocaleString('en-UA', formatDay))
                    break;

                default:
                    changeDate(objSelectDay.toLocaleString('uk-UA', formatDay))
                    break
            }

        } else {
            changeDate(null)
        }
    }, [selectDate])
    useEffect(() => {
        if (branch) {
            switch (params?.lang) {
                case "ua":
                    changeBranchName(branch.name.find(lang => lang.id == 1).text)
                    break;

                case "ru":
                    changeBranchName(branch.name.find(lang => lang.id == 2).text)
                    break;

                case "en":
                    changeBranchName(branch.name.find(lang => lang.id == 3).text)
                    break;

                default:
                    changeBranchName(branch.name.find(lang => lang.default_language).text)
                    break
            }
        }
    }, [branch])
    useEffect(() => {
        if (group) {
            switch (params?.lang) {
                case "ua":
                    changeGroupName(group[0].name.find(item => item.id == 1).text)
                    break;

                case "ru":
                    changeGroupName(group[0].name.find(item => item.id == 2).text)
                    break;

                case "en":
                    changeGroupName(group[0].name.find(item => item.id == 3).text)
                    break;

                default:
                    changeGroupName(group[0].name.find(item => item.default_language).text)
                    break
            }
        }
    }, [group])
    useEffect(() => {
        if (typeExcursion && typeExcursion.name) {
            switch (typeExcursion.name) {
                case "Мала група":
                    changeTypeExcursionName(translates.small_group[params?.lang ? params.lang : 'default'])
                    break;

                case "Велика група":
                    changeTypeExcursionName(translates.big_group[params?.lang ? params.lang : 'default'])
                    break;

                case "Поквитково":
                    changeTypeExcursionName(translates.ticket[params?.lang ? params.lang : 'default'])
                    break;
            }
        }
    }, [typeExcursion])


    if (selectSlot) {
        return (
            <header className={classes.header}>

                <div className={classes.header_container}>

                    <NavLink to={backLink} className={classes.back}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px"
                                 viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
                                <g>
                                    <path
                                        d="M767.9,499.9L291.6,10l-59.4,61.3l416.6,428.7L232.1,928.7l59.5,61.3L767.9,499.9z"/>
                                </g>
                            </svg>
                        </div>
                        <span>
                            <Translate text={"previous_step"}/>
                        </span>
                    </NavLink>

                    <div className={classes.step}>
                        <Translate text={"step"}/> {step}/2
                    </div>

                </div>

                <div className={classes.columns}>

                    <div className={classes.column}>

                        <h1 className={classes.title}>
                            {groupName}
                        </h1>

                        {typeExcursionName &&
                            <span className={classes.type_excursion}>
                                {typeExcursionName}
                            </span>
                        }


                    </div>

                    <div className={classes.column} style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <p className={classes.location}>
                            <img src={location}/>
                            {branchName}
                        </p>
                    </div>

                    <div className={classes.column} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end"
                    }}>
                        <div>
                            {date &&
                                <div className={classes.select_day}>
                                    {date}
                                </div>
                            }
                            {selectSlot &&
                                <div className={classes.time_slot}>
                                    <svg width="21" height="21" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z"
                                              fill="#000"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M9 3.20001C9.55228 3.20001 10 3.64773 10 4.20001V8.38198L12.6472 9.70558C13.1412 9.95257 13.3414 10.5532 13.0944 11.0472C12.8474 11.5412 12.2468 11.7414 11.7528 11.4944L8.55279 9.89444C8.214 9.72505 8 9.37878 8 9.00001V4.20001C8 3.64773 8.44772 3.20001 9 3.20001Z"
                                              fill="#000"/>
                                    </svg>
                                    {selectSlot.time.start.time} - {selectSlot.time.end.time}
                                </div>
                            }
                        </div>


                    </div>

                </div>

            </header>
        );
    } else {
        return (
            <></>
        )
    }

};

export default PageFormHeader;