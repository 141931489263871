import React, {useEffect, useRef, useState} from 'react';
import classes from "./Checkout.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Error from "../UI/Error/Error";
import {addBooking, setDataClient} from "../../redux/reducers/bookingReducer";
import PageForm from "../UI/PageForm/PageForm";
import base64 from "base-64";
import shajs from "sha.js"
import InputMask from 'react-input-mask';
import Translate from "../UI/Translate/Translate";

const Checkout = ({refContainer}) => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const translates = useSelector(state => state.booking.translates)
    const bookingPrice = useSelector(state => state.booking.bookingPrice)
    const selectSlot = useSelector(state => state.booking.selectSlot)
    const selectDate = useSelector(state => state.booking.selectDate)
    const group = useSelector(state => state.booking.group)
    const selectTypeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const ticketGroup = useSelector(state => state.booking.ticketGroup)
    const tickets = useSelector(state => state.booking.tickets)

    const [currentExcursion, changeCurrentExcursion] = useState(null)
    const [nameError, changeNameError] = useState(null)
    const [surnameError, changeSurnameError] = useState(null)
    const [phoneError, changePhoneError] = useState(null)
    const [emailError, changeEmailError] = useState(null)
    const [confirmError, changeConfirmError] = useState(null)
    const [data, changeData] = useState(null)
    const [signature, changeSignature] = useState(null)
    const [orderID, changeOrderID] = useState(null)

    const refLiqpay = useRef(null)

    const onChangeName = (e) => {
        if (nameError) {
            let value = e.currentTarget.value;

            if (nameError == false) {
                changeNameError('Введіть ім\'я')
            } else {
                changeNameError(null)
            }
        }
    }
    const onChangeSurname = (e) => {
        if (surnameError) {
            let value = e.currentTarget.value;

            if (surnameError == false) {
                changeSurnameError('Введіть прізвище')
            } else {
                changeSurnameError(null)
            }
        }
    }
    const onChangePhone = (e) => {
        if (phoneError) {
            let value = e.currentTarget.value;

            if (value.match(/^((\+?3)?8)?0\d{9}$/) == null) {
                changePhoneError(translates.enter_the_correct_phone_number[params?.lang ? params.lang : 'default'])
            } else {
                changePhoneError(null)
            }
        }
    }
    const onChangeEmail = (e) => {
        if (emailError) {
            let value = e.currentTarget.value;

            if (value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) == null) {
                changeEmailError('Введіть корректний поштовий адрес')
            } else {
                changeEmailError(null)
            }
        }
    }
    const onChangeConfirm = (e) => {
        if (confirmError) {
            changeConfirmError(null)
        }
    }
    const submitForm = (e) => {
        e.preventDefault()
        let form = e.currentTarget
        let formData = {
            name: form.elements.name.value,
            surname: form.elements.surname.value,
            phone: form.elements.phone.value.replace(/[\s\-\(\)]/g, ''),
            email: form.elements.email.value,
            comment: form.elements.comment.value,
            confirmation_method: form.elements.confirmation_method.value
        }

        if (formData.name == false) {
            changeNameError(translates.enter_a_name[params?.lang ? params.lang : 'default'])
        } else {
            changeNameError(null)
        }

        // if (formData.surname == false) {
        //     changeSurnameError(translates.enter_your_surname[params?.lang ? params.lang : 'default'])
        // } else {
        //     changeSurnameError(null)
        // }

        if (formData.phone.match(/^((\+?3)?8)?0\d{9}$/) == null) {
            changePhoneError(translates.enter_the_correct_phone_number[params?.lang ? params.lang : 'default'])
        } else {
            changePhoneError(null)
        }

        if (formData.email.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) == null) {
            changeEmailError(translates.please_enter_a_valid_email_address[params?.lang ? params.lang : 'default'])
        } else {
            changeEmailError(null)
        }

        if (formData.confirmation_method == false) {
            changeConfirmError(translates.choose_a_verification_method[params?.lang ? params.lang : 'default'])
        } else {
            changeConfirmError(null)
        }

        if (
            formData.name == false ||
            // formData.surname == false ||
            phoneError ||
            emailError ||
            confirmError ||
            formData.phone.match(/^((\+?3)?8)?0\d{9}$/) == null ||
            formData.email.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) == null ||
            formData.confirmation_method == false
        ) {
            return false
        } else {
            dispatch(setDataClient(formData))

            let requestBooking = {
                excursion_id: selectTypeExcursion.id,
                client: {...formData},
                slot: {...selectSlot},
                date: {...selectDate},
                bookingPrice: bookingPrice
            }
            if (selectSlot.type == "group") {
                requestBooking.ticketGroup = {...ticketGroup}
            }
            if (selectSlot.type == "ticket") {
                requestBooking.tickets = [...tickets]
                requestBooking.language = {
                    "type": "language",
                    "id": 1,
                    "name": "Українська",
                    "price": 0
                }

            }
            if (currentExcursion.onlinePayment && orderID) {
                requestBooking.order_id = orderID
            }

           dispatch(addBooking(requestBooking, refLiqpay))

            let localStorage = window.localStorage;
            let jsonRequest = JSON.stringify(requestBooking)
            localStorage.setItem('liqpay', jsonRequest);

            if (currentExcursion.onlinePayment == false) {
                navigate(`/${params.lang}/${params.group}/thankyou`)
            }
        }

    }

    useEffect(() => {
        if (bookingPrice && selectTypeExcursion && currentExcursion) {
            let name = currentExcursion.name.find(lang => lang.default_language).text;
            let typeExcursion = currentExcursion.typeExcursion.name;
            let description = `Excursion - ${selectTypeExcursion.id}`
            let private_key = "sandbox_TkZKsBjfoC6RADsbmkhDa6a5WXksO2QQvK0WSTRz"
            let order_id = `order_id_${Date.now()}_${currentExcursion.id}`

            let json_string = JSON.stringify({
                public_key: "sandbox_i42737593441",
                version: "3",
                action: "pay",
                amount: bookingPrice,
                currency: "UAH",
                description: description,
                order_id: order_id,
                result_url: `https://calendar.0300.com.ua/${params?.lang}/${params?.group}/thankyou`,
                // result_url: `http://localhost:3000/${params?.lang}/${params?.group}/thankyou`,
                server_url: "https://api.0300.com.ua/api/buybooking/successfully",
            })
            let setData = base64.encode(json_string);
            let string_sha1 = private_key + setData + private_key
            let setSignature = shajs('sha1').update(string_sha1).digest('base64')

            changeOrderID(order_id)
            changeData(setData)
            changeSignature(setSignature)
        }
    }, [currentExcursion])
    useEffect(() => {
        if (selectSlot == null) {
            navigate(`/${params.lang}/${params.group}/booking`)
        }
    }, [])
    useEffect(() => {
        if (selectTypeExcursion && selectTypeExcursion.id) {
            changeCurrentExcursion(group.find(excursion => excursion.id == selectTypeExcursion.id))
        }
    }, [selectTypeExcursion])
    useEffect(() => {
        if (refContainer?.current) {
            let scroll = refContainer?.current;
            refContainer.current.scrollTop = 0
        }
    }, [])

    return (
        <PageForm step={2} backLink={`/${params.lang}/${params.group}/cart`}>

            <form className={classes.form} onSubmit={submitForm}>

                <div className={classes.personal_information}>
                    <h3>
                        <Translate text={"your_information"}/>: *
                    </h3>

                    <div>
                        <input
                            type="text"
                            name="name"
                            placeholder={translates.name[params?.lang ? params.lang : 'default']}
                            onChange={onChangeName}
                            className={(nameError) ? "error" : ""}
                        />
                        {nameError && typeof nameError == "string" &&
                            <Error>{nameError}</Error>
                        }
                    </div>

                    <div>
                        <input
                            type="text"
                            name="surname"
                            placeholder={translates.surname[params?.lang ? params.lang : 'default']}
                            onChange={onChangeSurname}
                            className={(surnameError) ? "error" : ""}
                        />
                        {surnameError && typeof surnameError == "string" &&
                            <Error>{surnameError}</Error>
                        }
                    </div>

                    <div>
                        <input
                            type="email"
                            name="email"
                            placeholder={"E-mail"}
                            // required={true}
                            className={(emailError) ? "error" : ""}
                            onChange={onChangeEmail}
                        />
                        {emailError && typeof emailError == "string" &&
                            <Error>{emailError}</Error>
                        }
                    </div>

                    <div>
                        <InputMask mask={"+380 99 999 9999"} onChange={onChangePhone}>
                            {(inputProps) => {
                                return (
                                    <input
                                        {...inputProps}
                                        type="tel"
                                        name="phone"
                                        placeholder={translates.phone_number[params?.lang ? params.lang : 'default']}
                                        // required={true}
                                        className={(phoneError) ? "error" : ""}

                                    />
                                )
                            }}

                        </InputMask>
                        {phoneError && typeof phoneError == "string" &&
                            <Error>{phoneError}</Error>
                        }
                    </div>

                </div>

                <div className={classes.additional_information}>

                    <div>

                        <h3><Translate text={'method_of_confirmation_of_reservation'}/>: *</h3>

                        <div className={classes.confirmation_method}>

                            <input
                                name="confirmation_method"
                                id="confirm_email"
                                value="email"
                                type="radio"
                                onChange={onChangeConfirm}
                            />
                            <label htmlFor="confirm_email" className={(confirmError) ? "error" : ""}>
                                <Translate text={"email"}/>
                            </label>


                            <input
                                name="confirmation_method"
                                id="confirm_phone"
                                value="phone"
                                type="radio"
                                onChange={onChangeConfirm}
                            />
                            <label htmlFor="confirm_phone" className={(confirmError) ? "error" : ""}>
                                <Translate text={"phone"}/>
                            </label>

                            <input
                                name="confirmation_method"
                                id="confirm_viber"
                                value="viber"
                                type="radio"
                                onChange={onChangeConfirm}
                            />
                            <label htmlFor="confirm_viber" className={(confirmError) ? "error" : ""}>
                                <Translate text={"viber"}/>
                            </label>

                            <input
                                name="confirmation_method"
                                id="confirm_telegram"
                                value="telegram"
                                type="radio"
                                onChange={onChangeConfirm}
                            />
                            <label htmlFor="confirm_telegram" className={(confirmError) ? "error" : ""}>
                                <Translate text={"telegram"}/>
                            </label>
                        </div>

                        {confirmError && typeof confirmError == "string" &&
                            <Error style={{textAlign: "center", margin: "10px 0px"}}>{confirmError}</Error>
                        }

                    </div>

                    <div>

                        <h3><Translate text={"comment"}/></h3>

                        <textarea
                            name="comment"
                            id="comment"
                            rows="4"
                            placeholder={translates.your_comment[params?.lang ? params.lang : 'default']}
                        />

                    </div>

                </div>

                <div className={classes.footer} style={{marginTop: "35px"}}>

                    <span className={classes.price}>
                        <Translate text={"sum"}/>: <span> {bookingPrice} UAH </span>
                    </span>

                    <button style={{maxWidth: "345px", width: "100%"}}>
                        {currentExcursion && currentExcursion.onlinePayment &&
                            <Translate text={"buy_an_excursion"}/>
                        }
                        {currentExcursion && currentExcursion.onlinePayment == false &&
                            <Translate text={"reserve"}/>
                        }

                    </button>

                    <p className={classes.confirm}>
                        <Translate text={"by_clicking_the_buy"}/> <a><Translate text={"user_agreement"}/></a>
                    </p>

                </div>

            </form>

            {data && signature && currentExcursion.onlinePayment &&
                <>
                    <form
                        method="POST"
                        action="https://www.liqpay.ua/api/3/checkout"
                        acceptCharset="utf-8"
                    >
                        <input type="hidden" name="data" value={data}/>
                        <input type="hidden" name="signature" value={signature}/>
                        <button ref={refLiqpay} style={{display: "none"}}>
                            Перейти до оплати
                        </button>
                    </form>
                </>
            }

        </PageForm>
    );
};

export default Checkout;