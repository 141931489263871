import React, {useEffect, useState} from 'react';
import classes from "./Multiple.module.scss";

const Multiple = ({options, placeholder, onChange}) => {
    const [activeList, changeActiveList] = useState(false)
    const [showPlaceholder, changePlaceholder] = useState(true)
    const classList = [classes.list]

    if(activeList) classList.push(classes.list_active)

    const onChangeItem = (e) => {
        let value = Number(e.currentTarget.value);
        onChange(value)
    }
    const onClickHeader = (e) => {
        changeActiveList(activeList == false ? true : false)
    }

    useEffect(() => {
        if(options){
            if(options.find(item => item.selected == true)){
                changePlaceholder(false)
            } else {
                changePlaceholder(true)
            }
        }

    }, [options])

    if(options){
        return (
            <div className={classes.container}>

                <header className={classes.header} onClick={onClickHeader}>
                    { showPlaceholder &&
                        <span className={classes.placeholder}>
                            {placeholder}
                        </span>
                    }
                    {
                        options.map(option => {
                            if(option.selected){
                                return(
                                    <span className={classes.placeholder} key={option.name + " " + option.value}>
                                        {option.name}
                                    </span>
                                )
                            }

                        })
                    }
                </header>

                <div className={classList.join(" ")}>
                    {
                        options.map(option => {
                            return(
                                <label key={option.name + " " + option.value} className={classes.item}>
                                    <input
                                        type="checkbox"
                                        onChange={onChangeItem}
                                        value={option.value}
                                        checked={option.selected }
                                    />
                                    {option.name}
                                </label>
                            )
                        })
                    }
                </div>

            </div>
        );
    } else {
        return <></>
    }
};

export default Multiple;