import React from 'react';
import classes from "./Tabs.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {setSelectDate, setSelectSlot, setSelectTypeExcursion} from "../../../redux/reducers/bookingReducer";
import {useParams} from "react-router-dom";

const Tabs = ({group}) => {
    const dispatch = useDispatch()
    const params = useParams()
    
    const translates = useSelector(state => state.booking.translates)
    const typeExcursion = useSelector(state => state.booking.selectTypeExcursion)

    const setTypeExcursion = (typeExcursion) => {
        dispatch(setSelectTypeExcursion(typeExcursion))
        dispatch(setSelectDate(null))
        dispatch(setSelectSlot(null))
    }
    return (
        <div className={classes.tabs}>
            {group.map(booking => {
                let classTab = [classes.tab];
                if(typeExcursion && typeExcursion.key == booking.typeExcursion.key){
                    classTab.push(classes.tab_active)
                }
                let typeExcursionName = null

                switch (booking.typeExcursion.name){
                    case "Мала група":
                        typeExcursionName = translates.small_group[params?.lang ? params.lang : 'default']
                        break;

                    case "Велика група":
                        typeExcursionName = translates.big_group[params?.lang ? params.lang : 'default']
                        break;

                    case "Поквитково":
                        typeExcursionName = translates.ticket[params?.lang ? params.lang : 'default']
                        break;

                    default:
                        typeExcursionName = translates.ticket.default
                        break;
                }
                return(
                    <span
                        className={classTab.join(' ')}
                        key={booking.id}
                        onClick={() => {setTypeExcursion({
                            ...booking.typeExcursion,
                            id: booking.id
                        })}}
                    >
                        {typeExcursionName}
                    </span>
                )
            })}
        </div>
    );
};

export default Tabs;