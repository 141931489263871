import React, {useEffect, useRef, useState} from 'react';
import classes from "./Ticket.module.scss";
import Error from "../Error/Error";
import {useDispatch, useSelector} from "react-redux";
import {
    applyOptions,
    applyOptionsGroup,
    checkCertificate,
    checkPromocode, clearCertificate, clearPromocode,
    getDiscounts, getOptions,
    setBasePriceTicket,
    updateBookingPrice
} from "../../../redux/reducers/bookingReducer";
import {applyDiscount} from "../../../redux/reducers/bookingReducer";
import Multiple from "../../Multiple/Multiple";
import Translate from "../../UI/Translate/Translate";
import {useParams} from "react-router-dom";
import Modal from "../Modal/Modal";

const Ticket = ({number, ticket, deleteTicket}) => {
    const dispatch = useDispatch()
    const params = useParams()
    
    const translates = useSelector(state => state.booking.translates)
    const discounts = useSelector(state => state.booking.discounts)
    const options = useSelector(state => state.booking.options)
    const selectTypeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const selectDate = useSelector(state => state.booking.selectDate)
    const tickets = useSelector(state => state.booking.tickets)

    const [typeDiscount, changeTypeDiscount] = useState(null)
    const [certificateError, changeCertificateError] = useState(null)
    const [promocodeError, changePromocodeError] = useState(null)
    const [optionsList, changeOtionsList] = useState([])
    const [discountDescription, changeDescription] = useState({
        active: false,
        text: ""
    })

    const refPromocode = useRef(null)
    const refCertificate = useRef(null)

    const classNameCertificate = []
    if(certificateError) classNameCertificate.push("error")

    const classNamePromocode = []
    if(promocodeError) classNamePromocode.push("error")

    if(
        ticket.discount &&
        ticket.discount.code
    ) {
        if(ticket.discount.type == "certificate" && ticket.discount.certificateId){
            classNameCertificate.push("success")
        }
        if(ticket.discount.type == "promocode" && ticket.discount.promocodeId) {
            classNamePromocode.push("success")
        }
    }

    let price = Number(ticket.price.toFixed(0))
    if (ticket.options) {
        ticket.options.forEach(option => {
            let newPrice = price + option.price
            price = Number(newPrice.toFixed(0))
        })
    }

    const onChangeTypeDiscount = (e) => {
        let value = e.currentTarget.value;
        changeTypeDiscount(value)
        dispatch(setBasePriceTicket(ticket.id))
    }
    const onChangeDiscount = (e) => {
        e.preventDefault()
        let value = e.currentTarget.value

        if (e.currentTarget.value != "false") {
            let discount_id = Number(value);
            let currentDiscount = discounts.find(item => item.id == discount_id)
            let percent = currentDiscount.percent;
            dispatch(applyDiscount(ticket.id, percent, discount_id))
            dispatch(updateBookingPrice())

            let discountDescription = ""
            switch (params?.lang) {
                case "ua":
                    discountDescription = currentDiscount.description.find(item => item.id == 1).text
                    break

                case "ru":
                    discountDescription = currentDiscount.description.find(item => item.id == 2).text
                    break

                case "en":
                    discountDescription = currentDiscount.description.find(item => item.id == 3).text
                    break

                default:
                    discountDescription = currentDiscount.description.find(item => item.default_language).text
                    break;
            }

            changeDescription({
                active: false,
                text: discountDescription
            })
        } else {
            changeDescription({
                active: false,
                text: ""
            })
            dispatch(setBasePriceTicket(ticket.id))
            dispatch(updateBookingPrice())
        }
    }
    const onChangeCertificate = (e) => {
        e.preventDefault()
        if (certificateError) {
            changeCertificateError(null)
        }
        dispatch(setBasePriceTicket(ticket.id))
        dispatch(clearCertificate(ticket.id))
    }
    const callbackCheckCertificate = (e) => {
        e.preventDefault()
        const code = Number(refCertificate.current.value);
        const obj = {
            excursion_id: selectTypeExcursion.id,
            code: code,
            date: {...selectDate},
            count: tickets.filter(item => item.discount.code == code && item.discount.type == "certificate").length,
        }
        dispatch(checkCertificate(obj, ticket.id))
    }
    const onChangePromocode = (e) => {
        e.preventDefault()
        if (promocodeError) {
            changePromocodeError(null)
        }
        dispatch(setBasePriceTicket(ticket.id))
        dispatch(clearPromocode(ticket.id))
    }
    const callbackCheckPromocode = (e) => {
        e.preventDefault()
        const code = Number(refPromocode.current.value);
        const obj = {
            excursion_id: selectTypeExcursion.id,
            code: code,
            date: {...selectDate},
            count: tickets.filter(item => item.discount.code == code && item.discount.type == "promocode").length,
        }
        dispatch(checkPromocode(obj, ticket.id))
    }
    const onChangeOptions = (selected) => {
        let prevArr = optionsList;
        let newArr = prevArr.map(item => {
            if (item.value == selected) {
                item.selected = item.selected == false ? true : false
                return item
            } else {
                return item
            }
        })
        changeOtionsList(newArr)
        dispatch(applyOptions(
            ticket.id,
            newArr.filter(option => option.selected == true).map(item => {
                return ({
                    id: item.value,
                    name: item.name,
                    price: item.price
                })
            })
        ))
    }
    const showDiscountDescription = (e) => {
        e.preventDefault()
        if(discountDescription.text){
            changeDescription({
                ...discountDescription,
                active: true,
            })
        }
    }
    const hideDiscountDescription = (e) => {
        e.preventDefault()
        changeDescription({
            ...discountDescription,
            active: false,
        })
    }

    useEffect(() => {
        if (discounts == null) {
            let id = selectTypeExcursion.id;
            dispatch(getDiscounts(id))
        }
    }, [discounts])
    useEffect(() => {
        if (options == null) {
            let id = selectTypeExcursion.id;
            dispatch(getOptions(id))
        }
        if (options) {
            changeOtionsList(
                options.map(option => {
                    let nameOption = ""
                    switch (params?.lang) {
                        case "ua":
                            nameOption = option.name.find(lang => lang.id == 1).text
                            break

                        case "ru":
                            nameOption = option.name.find(lang => lang.id == 2).text
                            break

                        case "en":
                            nameOption = option.name.find(lang => lang.id == 3).text
                            break

                        default:
                            nameOption = option.name.find(lang => lang.default_language).text
                            break
                    }
                    return (
                        {
                            name: `${nameOption} (+${option.price} UAH)`,
                            value: option.id,
                            price: option.price,
                            selected: false
                        }
                    )
                })
            )
        }
    }, [options])
    useEffect(() => {
        if (ticket.discount.type == "certificate" && ticket.discount.code == "") {
            changeCertificateError("Недійсний сертифікат")
        }
        if (ticket.discount.type == "certificate" && ticket.discount.code) {
            changeCertificateError(null)
        }
        if (ticket.discount.type == "promocode" && ticket.discount.code == "") {
            changePromocodeError("Недійсний промокод")
        }
        if (ticket.discount.type == "promocode" && ticket.discount.code) {
            changePromocodeError(null)
        }
    }, [ticket.discount])

    return (
        <>
            <div className={classes.ticket}>

                <div>
                    <svg className={classes.icon_ticket} xmlns="http://www.w3.org/2000/svg" width="52" height="32"
                         viewBox="0 0 52 32">
                        <g id="Layer_15" dataName="Layer 15" transform="translate(-6 -29)">
                            <path id="Контур_161" dataName="Контур 161"
                                  d="M7.188,37.024l.3.512a3.936,3.936,0,0,1,1.036-.4A3.991,3.991,0,0,0,7.188,37.024Z"
                                  fill="#ed539d"/>
                            <path id="Контур_167" dataName="Контур 167"
                                  d="M54,45a4,4,0,0,0,4,4v8a4,4,0,0,1-4,4H10a4,4,0,0,1-4-4V49a4,4,0,0,0,0-8V33a4,4,0,0,1,4-4H54a4,4,0,0,1,4,4v8A4,4,0,0,0,54,45Z"
                                  fill="#d9dce5"/>
                            <path id="Контур_168" dataName="Контур 168"
                                  d="M58,41V33a4,4,0,0,0-4-4H52a4,4,0,0,1,4,4v8a4,4,0,0,0,0,8v8a4,4,0,0,1-4,4h2a4,4,0,0,0,4-4V49a4,4,0,0,1,0-8Z"
                                  fill="#bac1d4"/>
                            <path id="Контур_169" dataName="Контур 169"
                                  d="M6,41a4.06,4.06,0,0,1,.471.047A4,4,0,0,0,6,40.379Z" fill="#ebbf00"/>
                            <rect id="Прямоугольник_293" dataName="Прямоугольник 293" width="8" height="4"
                                  transform="translate(46 33)" fill="#bac1d4"/>
                            <path id="Контур_171" dataName="Контур 171"
                                  d="M42,31a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V32A1,1,0,0,0,42,31Z" fill="#bac1d4"/>
                            <path id="Контур_172" dataName="Контур 172"
                                  d="M42,36a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V37A1,1,0,0,0,42,36Z" fill="#bac1d4"/>
                            <path id="Контур_173" dataName="Контур 173"
                                  d="M42,41a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V42A1,1,0,0,0,42,41Z" fill="#bac1d4"/>
                            <path id="Контур_174" dataName="Контур 174"
                                  d="M42,46a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V47A1,1,0,0,0,42,46Z" fill="#bac1d4"/>
                            <path id="Контур_175" dataName="Контур 175"
                                  d="M42,51a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V52A1,1,0,0,0,42,51Z" fill="#bac1d4"/>
                            <path id="Контур_176" dataName="Контур 176"
                                  d="M42,56a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V57A1,1,0,0,0,42,56Z" fill="#bac1d4"/>
                            <path id="Контур_177" dataName="Контур 177"
                                  d="M45,53a1,1,0,0,0,1,1h8a1,1,0,0,0,0-2H46A1,1,0,0,0,45,53Z" fill="#bac1d4"/>
                            <path id="Контур_178" dataName="Контур 178" d="M54,56H46a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
                                  fill="#bac1d4"/>
                        </g>
                    </svg>
                    <span className={classes.number_ticket}>{number}</span>
                </div>

                <div className={classes.discounts}>

                    <div>
                        <h3><Translate text={"discount_certificate_promo_code"}/></h3>

                        <div className={classes.selects}>
                            <select name="typeDiscount" id="typeDiscount" onChange={onChangeTypeDiscount}>
                                <option value={false}><Translate text={"standard_price"}/></option>
                                <option value="discount"><Translate text={"discount"}/></option>
                                <option value="certificate"><Translate text={"certificate"}/></option>
                                <option value="promocode"><Translate text={"promocode"}/></option>
                            </select>

                            {typeDiscount == "discount" && discounts &&
                                <div className={classes.container_discount}>

                                    <select name="discount" id="discount" onChange={onChangeDiscount}>
                                        <option value={false}>
                                            <Translate text={"сhoose_a_discount"}/>
                                        </option>
                                        {discounts.map(discount => {
                                            let nameDiscount = ""
                                            switch (params?.lang) {
                                                case "ua":
                                                    nameDiscount = discount.name.find(lang => lang.id == 1).text
                                                    break

                                                case "ru":
                                                    nameDiscount = discount.name.find(lang => lang.id == 2).text
                                                    break

                                                case "en":
                                                    nameDiscount = discount.name.find(lang => lang.id == 3).text
                                                    break

                                                default:
                                                    nameDiscount = discount.name.find(lang => lang.default_language).text
                                                    break
                                            }
                                            return (
                                                <option key={discount.id + "_" + discount.percent} value={discount.id}>
                                                    {nameDiscount}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    {discountDescription.text &&
                                        <div className={classes.discount_description} onClick={showDiscountDescription}>
                                            <svg id="help_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24">
                                                <path id="Path_179" dataName="Path 179" d="M0,0H24V24H0Z" fill="none"/>
                                                <path id="Path_180" dataName="Path 180"
                                                      d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,17H11V17h2Zm2.07-7.75-.9.92A3.4,3.4,0,0,0,13,15H11v-.5a4.025,4.025,0,0,1,1.17-2.83l1.24-1.26A1.955,1.955,0,0,0,14,9a2,2,0,0,0-4,0H8a4,4,0,0,1,8,0A3.182,3.182,0,0,1,15.07,11.25Z"
                                                      fill="#bac1d4"/>
                                            </svg>
                                        </div>
                                    }

                                </div>
                            }
                            {typeDiscount == "certificate" &&
                                <div style={{position: "relative"}}>
                                    <input
                                        ref={refCertificate}
                                        name="certificate"
                                        type="number"
                                        onChange={onChangeCertificate}
                                        placeholder={translates.certificate[params?.lang ? params.lang : 'default']}
                                        className={classNameCertificate}
                                    />
                                    {certificateError && typeof certificateError == "string" &&
                                        <Error style={{marginBottom: "0px"}}>{certificateError}</Error>
                                    }
                                    <a
                                        href="#"
                                        onClick={callbackCheckCertificate}
                                        style={{
                                            display: "block",
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px"
                                        }}
                                    >
                                        <Translate text={'apply'}/>
                                    </a>
                                </div>
                            }
                            {typeDiscount == "promocode" &&
                                <div style={{position: "relative"}}>
                                    <input
                                        ref={refPromocode}
                                        name="promocode"
                                        type="number"
                                        placeholder={translates.promocode[params?.lang ? params.lang : 'default']}
                                        onChange={onChangePromocode}
                                        className={classNamePromocode}
                                    />
                                    {promocodeError && typeof promocodeError == "string" &&
                                        <Error style={{marginBottom: "0px"}}>{promocodeError}</Error>
                                    }
                                    <a
                                        href="#"
                                        onClick={callbackCheckPromocode}
                                        style={{
                                            display: "block",
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px"
                                        }}
                                    >
                                        <Translate text={'apply'}/>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>

                    {optionsList && optionsList.length !== 0 &&
                        <div>
                            <h3 style={{marginTop: "15px"}}>
                                <Translate text={'additional_options'}/>
                            </h3>

                            <div className={classes.selects}>
                                {options &&
                                    <Multiple
                                        options={optionsList}
                                        placeholder={translates.select_options[params?.lang ? params.lang : 'default']}
                                        onChange={onChangeOptions}
                                    />
                                }
                            </div>

                        </div>
                    }

                </div>

                <div className={classes.price_block}>

                    {number != 1 &&
                        <div className={classes.delete} onClick={(e) => {
                            deleteTicket(e, ticket.id)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="delete_black_24dp" width="24" height="24"
                                 viewBox="0 0 24 24">
                                <path id="Контур_114" dataName="Контур 114"
                                      d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM19,4H15.5l-1-1h-5l-1,1H5V6H19Z"
                                      fill="#bac1d4"/>
                            </svg>
                        </div>
                    }

                    <span className={classes.price}>
                    {price} UAH
                </span>
                </div>

            </div>

            <Modal active={discountDescription.active} title={"Опис знижки"}>
                <div>
                    <p style={{textAlign: "center"}}>
                        {discountDescription.text}
                    </p>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <button onClick={hideDiscountDescription}>
                        <Translate text={"close"}/>
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default Ticket;