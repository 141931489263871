import React, {useEffect, useState} from 'react';
import classes from "./ThankYou.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Translate from "../UI/Translate/Translate";
import {getExcursions, getTranslate} from "../../redux/reducers/bookingReducer";
import PageLoader from "../UI/PageLoader/PageLoader";

const ThankYou = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const localStorage = window.localStorage;
    const data = JSON.parse(localStorage.getItem('liqpay'))
    const translates = useSelector(state => state.booking.translates)
    const group = useSelector(state => state.booking.group)
    const [currentExcursion, changeCurrentExcursion] = useState(null)
    const [nameExcursion, changeNameExcursion] = useState(null)
    const [typeExcursion, changeTypeExcursion] = useState(null)
    const callbackHome = (e) => {
        e.preventDefault()
        navigate(`/${params.lang}/${params.group}/booking`)
    }

    useEffect(() => {
        return () => {
            localStorage.removeItem('liqpay');
        }
    }, [])
    useEffect(() => {
        dispatch(getExcursions({
            id: params.group
        }))
        if(translates == false){
            dispatch(getTranslate())
        }
    }, [])
    useEffect(() => {
        if(group){
            let excursion = group.find(item => item.id == data.excursion_id)
            changeCurrentExcursion(excursion)
        }
    }, [group])
    useEffect(() => {
        if(currentExcursion){
            let name = ""
            switch (params?.lang){
                case "ua":
                    name = currentExcursion.name.find(lang => lang.id == 1).text
                    break;

                case "ru":
                    name = currentExcursion.name.find(lang => lang.id == 2).text
                    break;

                case "en":
                    name = currentExcursion.name.find(lang => lang.id == 3).text
                    break;

                default:
                    name = currentExcursion.name.find(lang => lang.default_language).text
                    break
            }
            changeNameExcursion(name)

            let typeExcursionName = ""
            switch (currentExcursion.typeExcursion.key){
                case "small_group":
                    typeExcursionName = translates.small_group[params?.lang ? params.lang : 'default']
                    break;

                case "big_group":
                    typeExcursionName = translates.big_group[params?.lang ? params.lang : 'default']
                    break;

                case "ticket":
                    typeExcursionName = translates.ticket[params?.lang ? params.lang : 'default']
                    break;
            }
            changeTypeExcursion(typeExcursionName)
        }
    }, [currentExcursion])

    if(translates && data) {
        return (
            <div className={classes.container}>

                <h1 className={classes.title}>
                    <Translate text={"successfully_placed"}/>
                </h1>

                {nameExcursion && typeExcursion &&
                    <h3 className={classes.title}>
                        {nameExcursion} ({typeExcursion})
                    </h3>
                }

                <div className={classes.data}>

                    <div>
                        <p> {data.client.name + " " + data.client.surname} </p>
                        <p> {data.client.email} </p>
                        <p> {data.client.phone} </p>
                        <p>
                            <Translate text={"date"}/>: {data.date.day < 10 ? "0" + data.date.day : data.date.day}.{data.date.month < 10 ? "0" + data.date.month : data.date.month }.{data.date.year}
                        </p>

                        <p>
                            <Translate text={"time"}/>: {data.slot.time.start.time} - {data.slot.time.end.time}
                        </p>
                        <p className={classes.sum}>
                            <Translate text={"sum"}/>: <span className={classes.price}>{data.bookingPrice} UAH</span>
                        </p>
                    </div>

                    <div>

                        <p>
                            <Translate text={"tickets"}/>: { data.slot.type == "group" ? data.ticketGroup.count.value : data.tickets.length}
                        </p>

                        <p>
                            <Translate text={"confirmation_of_booking"}/>: {data.client.confirmation_method}
                        </p>

                        {data.client.comment &&
                            <p>
                                <Translate text={"comment"}/>: {data.client.comment}
                            </p>
                        }

                    </div>
                </div>



                <h1 className={classes.thank}>
                    <Translate text={"we_are_waiting_for_the_meeting"}/>
                </h1>

                <button onClick={callbackHome} className={classes.link}>
                    <Translate text={"home_page"}/>
                </button>
            </div>
        );
    } else {
        return (
            <PageLoader/>
        )
    }
};

export default ThankYou;