import Steps from "../components/Steps/Steps";
import Booking from "../components/Booking/Booking";
import Cart from "../components/Cart/Cart";
import Checkout from "../components/Checkout/Checkout";
import ThankYou from "../components/ThankYou/ThankYou";

export const publicRoutes = [
    { name: "booking", path: '/:lang/:group/booking', component: () => <Booking />},
    { name: "Cart", path: '/:lang/:group/cart', component: (props) => <Cart {...props} />},
    { name: "Checkout", path: '/:lang/:group/checkout', component: (props) => <Checkout {...props} />},
    // { name: "Params", path: '/:group/params-booking', component: () => <Checkout />},
    { name: "thankyou", path: '/:lang/:group/thankyou', component: () => <ThankYou />},
]