import React, {useEffect, useState} from 'react';
import classes from "./FormTicket.module.scss";
import Ticket from "../../UI/Ticket/Ticket";
import {useDispatch, useSelector} from "react-redux";
import {addTicket, clearTickets, deleteTicket, updateBookingPrice} from "../../../redux/reducers/bookingReducer";
import {useNavigate, useParams} from "react-router-dom";
import Error from "../../UI/Error/Error";
import Modal from "../../UI/Modal/Modal";
import Translate from "../../UI/Translate/Translate";

const FormTicket = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    
    const translates = useSelector(state => state.booking.translates)
    const selectSlot = useSelector(state => state.booking.selectSlot)
    const tickets = useSelector(state => state.booking.tickets)
    const bookingPrice = useSelector(state => state.booking.bookingPrice)
    const [confirmError, changeConfirmError] = useState(null)
    const [confirmState, changeConfirmState] = useState(false)
    const [ticketsAvailable, changeTicketsAvailable] = useState(selectSlot.tickets)
    const [errors, changeErrors] = useState("")
    const [modalDiscount, changeModalDiscount] = useState(false)

    const onChangeConfirm = (e) => {
        let value = e.currentTarget.checked
        changeConfirmState(value)
        if (confirmError) {
            changeConfirmError(null)
        }
    }
    const callbackAddTicket = (e) => {
        e.preventDefault()

        if (ticketsAvailable) {
            let rand = Math.floor(1 + Math.random() * (10000000 + 1 - 1))

            if (!tickets.find(item => item.id == rand)) {
                dispatch(addTicket({
                    type: "ticket",
                    id: rand,
                    discount: false,
                    basePrice: selectSlot.price,
                    price: selectSlot.price,
                }))
                changeTicketsAvailable(ticketsAvailable - 1)
            }

        }
    }
    const callbackDeleteTicket = (e, id) => {
        e.preventDefault()
        dispatch(deleteTicket(id))
        changeTicketsAvailable(ticketsAvailable + 1)
    }
    const onSubmitForm = (e) => {
        e.preventDefault()
        let form = e.currentTarget
        if (tickets.find(item => item.discount && item.discount.type == "certificate" && item.discount.code == "")) {
            changeErrors("Застосуйте сертифікат")
            return false
        }
        if (tickets.find(item => item.discount && item.discount.type == "promocode" && item.discount.code == "")) {
            changeErrors("Застосуйте промокод")
            return false
        }
        navigate(`/${params.lang}/${params.group}/checkout`)
    }

    useEffect(() => {
        if (selectSlot && tickets.length == 0) {
            let rand = Math.floor(1 + Math.random() * (10000000 + 1 - 1))

            dispatch(addTicket({
                type: "ticket",
                id: rand,
                discount: false,
                basePrice: selectSlot.price,
                price: selectSlot.price,

            }))
            changeTicketsAvailable(ticketsAvailable - 1)
        }
    }, [selectSlot])
    useEffect(() => {
        dispatch(updateBookingPrice())
    }, [tickets])

    return (
        <div>
            <form className={classes.form} onSubmit={onSubmitForm}>

                <header className={classes.header}>
                    <h3><Translate text={'choose_tickets'}/>*</h3>
                    <h3><Translate text={'tickets_available_from'}/> {ticketsAvailable}</h3>
                </header>

                {tickets.length != 0 &&
                    <div>
                        {tickets.map((ticket, index) => {
                            return (
                                <Ticket
                                    key={ticket.id}
                                    number={index + 1}
                                    ticket={ticket}
                                    deleteTicket={callbackDeleteTicket}
                                />
                            )
                        })}
                    </div>
                }

                <div className={classes.row}>

                    {ticketsAvailable != 0 &&
                        <button
                            onClick={callbackAddTicket}
                            style={{
                                background: "#D2DFFF", padding: "10px 35px", minHeight: "unset", margin: "0px"
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" id="add_circle_black_24dp_1_"
                                 data-name="add_circle_black_24dp (1)" width="24" height="24" viewBox="0 0 24 24">
                                <path id="Контур_5" data-name="Контур 5" d="M0,0H24V24H0Z" fill="none"/>
                                <path id="Контур_6" data-name="Контур 6"
                                      d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5,11H13v4H11V13H7V11h4V7h2v4h4Z"/>
                            </svg>
                            <Translate text={'add_ticket'}/>
                        </button>
                    }

                    <a
                        href="#"
                        style={{textAlign: "right", display: "block"}}
                        onClick={(e) => {
                            e.preventDefault()
                            changeModalDiscount(true)
                        }}
                    >
                        <Translate text={'how_can_i_use_the_discounts'}/>
                    </a>

                </div>

                {bookingPrice &&
                    <>
                        <div className={classes.accept} style={{marginTop: "40px"}}>

                            <input
                                id="confirm"
                                name="confirm"
                                type="checkbox"
                                className={(confirmError) ? "error" : ""}
                                onChange={onChangeConfirm}
                                checked={confirmState}
                            />

                            <label htmlFor="confirm">
                                <Translate text={'i_confirm'}/>
                            </label>

                        </div>

                        <div className={classes.footer}>

                            <span className={classes.price}>
                                <Translate text={"sum"}/>: <span> {bookingPrice} UAH </span>
                            </span>

                            <button disabled={confirmState ? false : true} style={{maxWidth: "345px", width: "100%"}}>
                                <Translate text={'checkout'}/>
                            </button>

                        </div>
                    </>
                }

            </form>

            <Modal active={modalDiscount}
                   title={translates.how_can_i_use_the_discounts[params?.lang ? params.lang : 'default']}>

                <p style={{textAlign: "center"}}>
                    <Translate text={"discount_description"}/>
                </p>


                <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                        style={{
                            maxWidth: "345px",
                            width: "100%",
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            changeModalDiscount(false)
                        }}
                    >
                        <Translate text={'close'}/>
                    </button>
                </div>
            </Modal>

        </div>
    )
};

export default FormTicket;