import React, {useEffect, useRef} from 'react';
import classes from "./Calendar.module.scss";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCalendar, setSelectDate} from "../../../redux/reducers/bookingReducer";
import {useParams} from "react-router-dom";

Date.prototype.daysInMonth = function () {
    return 32 - new Date(this.getFullYear(), this.getMonth(), 32).getDate();
};

const Calendar = () => {
    const dispatch = useDispatch()
    const selectDate = useSelector(state => state.booking.selectDate)
    const selectTypeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const calendar = useSelector(state => state.booking.calendar)
    const params = useParams()

    const classCurrentDate = new Date()
    const [unixDate, changeUnixDate] = useState(classCurrentDate.valueOf())
    const classDate = new Date(unixDate)
    const classDateFirstDayMonth = new Date(classDate.getFullYear(), classDate.getMonth(), 1)
    const date = {
        day: classDate.getDate(),
        month: classDate.getMonth() + 1,
        year: classDate.getFullYear(),
        lenghtMonth: classDate.daysInMonth(),
        firstDayOfWeek: (classDateFirstDayMonth.getDay() == 0 ? 7 : classDateFirstDayMonth.getDay())
    }

    const days = Array.from({length: date.lenghtMonth}, (_, index) => index + 1)
    const emptyDays = Array.from({length: date.firstDayOfWeek - 1}, (_, index) => index + 1)
    const [checkedDay, changeCheckedDay] = useState(null)
    const formatDay = {year: 'numeric', month: 'long',};
    let daysWeek = null
    let labelMonth = classDate.toLocaleString('uk-UA', formatDay)
    switch (params?.lang){
        case "ua":
            daysWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "НД"]
            labelMonth = classDate.toLocaleString('uk-UA', formatDay)
            break;

        case "ru":
            daysWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"]
            labelMonth = classDate.toLocaleString('ru-UA', formatDay)
            break;

        case "en":
            daysWeek = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"]
            labelMonth = classDate.toLocaleString('en-UA', formatDay)
            break;

        default:
            daysWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "НД"]
            labelMonth = classDate.toLocaleString('uk-UA', formatDay)
            break
    }

    const callbackDay = (e) => {
        e.preventDefault()
        let day = Number(e.currentTarget.innerText)
        changeCheckedDay(day)

        if (calendar && calendar.find(item => item.day == day && item.month == date.month && item.year == date.year)?.status == false) {
            dispatch(setSelectDate(null))
            return false
        }

        if (
            date.year > classCurrentDate.getFullYear() ||
            (date.year == classCurrentDate.getFullYear() && date.month > classCurrentDate.getMonth() + 1) ||
            (date.year == classCurrentDate.getFullYear() && date.month == classCurrentDate.getMonth() + 1 && day >= classCurrentDate.getDate())
        ) {
            dispatch(setSelectDate({
                day: day,
                month: date.month,
                year: date.year
            }))
        } else {
            dispatch(setSelectDate(null))
        }
    }
    const callbackChangeMonth = (e) => {
        e.preventDefault()
        let value = e.currentTarget.value

        if (value) {
            let arrValue = value.split("-").map(item => Number(item))
            let dateObj = null

            dateObj = new Date(arrValue[0], arrValue[1] - 1, 1)

            if (dateObj.getMonth() == classCurrentDate.getMonth() && dateObj.getFullYear() == classCurrentDate.getFullYear()) {
                changeUnixDate(classCurrentDate.valueOf())
            } else {
                changeUnixDate(dateObj.valueOf())
            }
            changeCheckedDay(null)
            dispatch(setSelectDate(null))
        }

    }
    const prevMonth = (e) => {
        e.preventDefault()

        let dateObj = null

        if (classDate.getMonth() == 0) {
            dateObj = new Date(classDate.getFullYear() - 1, 11, 1)
        } else {
            dateObj = new Date(classDate.getFullYear(), classDate.getMonth() - 1, 1)
        }

        if (dateObj.getMonth() == classCurrentDate.getMonth() && dateObj.getFullYear() == classCurrentDate.getFullYear()) {
            changeUnixDate(classCurrentDate.valueOf())
        } else {
            changeUnixDate(dateObj.valueOf())
        }

        changeCheckedDay(null)
        dispatch(setSelectDate(null))
    }
    const nextMonth = (e) => {
        e.preventDefault()
        let dateObj = null

        if (classDate.getMonth() == 11) {
            dateObj = new Date(classDate.getFullYear() + 1, 0, 1)
        } else {
            dateObj = new Date(classDate.getFullYear(), classDate.getMonth() + 1, 1)
        }

        if (dateObj.getMonth() == classCurrentDate.getMonth() && dateObj.getFullYear() == classCurrentDate.getFullYear()) {
            changeUnixDate(classCurrentDate.valueOf())
        } else {
            changeUnixDate(dateObj.valueOf())
        }

        changeCheckedDay(null)
        dispatch(setSelectDate(null))
    }

    useEffect(() => {
        let dataRequest = {
            id: selectTypeExcursion.id,
            month: date.month,
            year: date.year,
        }
        dispatch(getCalendar(dataRequest))
    }, [unixDate, selectTypeExcursion.id])
    useEffect(() => {
        if (selectDate == null) {
            changeCheckedDay(null)
        }
    }, [selectDate])
    useEffect( () => {
        if(
            calendar &&
            calendar.message != "Error" &&
            classCurrentDate &&
            date.year == classCurrentDate.getFullYear() &&
            date.month == classCurrentDate.getMonth() + 1 &&
            date.day == classCurrentDate.getDate()
        ){
            let currentDate = {
                day: classCurrentDate.getDate(),
                month: classCurrentDate.getMonth() + 1,
                year: classCurrentDate.getFullYear(),
            }
            changeCheckedDay(currentDate.day)
            dispatch(setSelectDate(currentDate))
        }
    }, [calendar])

    if (calendar) {
        return (
            <div className={classes.calendar}>

                <div className={classes.week_container}>
                    <button className={classes.week_button} onClick={prevMonth}>
                        {"◀"}
                    </button>

                    <div className={classes.container_input}>
                        <label className={classes.week_label} htmlFor="month">
                            {labelMonth}
                        </label>

                        <input
                            type="month"
                            name="month"
                            className={classes.week}
                            value={`${date.year}-${(date.month < 10) ? "0" + date.month : date.month}`}
                            onChange={callbackChangeMonth}
                        />
                    </div>

                    <button className={classes.week_button} onClick={nextMonth}>
                        {"▶"}
                    </button>
                </div>

                <div className={classes.days}>
                    {daysWeek.map(day => {
                        return (
                            <div key={day} className={classes.dayWeek}>
                                {day}
                            </div>
                        )
                    })}
                    {emptyDays.map(block => {
                        return (<div key={Math.random()}></div>)
                    })}
                    {
                        days.map((day, index) => {
                            let dayNumber = index + 1
                            let classDay = classes.day

                            switch (true) {
                                case (dayNumber < date.day || date.year < classCurrentDate.getFullYear() || date.month < classCurrentDate.getMonth() + 1 && date.year <= classCurrentDate.getFullYear()):
                                    classDay = classDay + " " + classes.last_day
                                    break
                                case (Array.isArray(calendar) && calendar.find(item => item.day == dayNumber && item.month == date.month && item.year == date.year)?.status == false):
                                    classDay = classDay + " " + classes.last_day
                                    break
                                case (dayNumber == checkedDay):
                                    classDay = classDay + " " + classes.checked_day
                                    break
                                case (dayNumber == date.day && classCurrentDate.getMonth() + 1 == date.month && classCurrentDate.getFullYear() == date.year):
                                    classDay = classDay + " " + classes.current_day
                                    break
                            }

                            return (
                                <div
                                    key={`${date.year}.${date.month}.${dayNumber}`}
                                    className={classDay}
                                    onClick={callbackDay}
                                >
                                    {dayNumber}
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        );
    } else {
        <></>
    }
};

export default Calendar;