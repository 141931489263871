import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import PageForm from "../UI/PageForm/PageForm";
import FormTicket from "./FormTicket/FormTicket";
import FormGroup from "./FormGroup/FormGroup";
import {useNavigate, useParams} from "react-router-dom";

const Cart = ({refContainer}) => {
    const params = useParams()
    const navigate = useNavigate()
    const selectTypeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const selectSlot = useSelector(state => state.booking.selectSlot)

    useEffect(() => {
        if(selectSlot == null){
            navigate(`/${params.lang}/${params.group}/booking`)
        }
    }, [])
    useEffect(() => {
        if(refContainer?.current){
            let scroll = refContainer?.current;
            refContainer.current.scrollTop = 0
        }
    }, [])

    return (
        <PageForm step={1} backLink={`/${params.lang}/${params.group}/booking`}>
            {selectSlot && selectTypeExcursion.key && selectTypeExcursion.key == "ticket" &&
                <FormTicket/>
            }
            {selectSlot && selectTypeExcursion.key && selectTypeExcursion.key != "ticket" &&
                <FormGroup/>
            }
        </PageForm>
    );
};

export default Cart;