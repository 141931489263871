import './assets/style/App.scss';
import {publicRoutes} from "./routes/publicRoutes";
import {BrowserRouter, Routes, Route, Link, useParams, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {getAllLanguages, getBranch, getExcursions, getTranslate} from "./redux/reducers/bookingReducer";
import {useEffect, useRef} from "react";
import PageLoader from "./components/UI/PageLoader/PageLoader";

const App = () => {
    const refContainer = useRef()
    const dispatch = useDispatch()
    const translates = useSelector(state => state.booking.translates)

    useEffect(() => {
        if(translates == false){
            dispatch(getAllLanguages())
            dispatch(getTranslate())
        }
    }, [])

    if(translates) {
        return (
            <div className="App">
                <div className="container" ref={refContainer}>
                    <BrowserRouter>
                        <Routes>
                            {publicRoutes.map(route =>
                                <Route key={route.path} path={route.path} element={<route.component refContainer={refContainer}/>}/>
                            )}
                        </Routes>
                    </BrowserRouter>
                </div>
            </div>
        );
    } else {
        return (
            <div className="App">
                <div className="container">
                    <PageLoader/>
                </div>
            </div>
        )
    }


}

export default App;
