import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom"
import classes from "./Booking.module.scss"
import location from "./../../assets/images/location.svg"
import {useDispatch, useSelector} from "react-redux";
import Tabs from "../UI/Tabs/Tabs";
import Calendar from "../UI/Calendar/Calendar";
import {
    setSlots,
    clearTicketGroupData,
    clearTickets,
    getExcursions, liqPay, setSelectSlot, setSelectDate
} from "../../redux/reducers/bookingReducer";
import Slot from "../UI/Slot/Slot";
import Translate from "../UI/Translate/Translate";

const Booking = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const allLanguages = useSelector(state => state.booking.allLanguages)
    const translates = useSelector(state => state.booking.translates)
    const group = useSelector(state => state.booking.group)
    const branch = useSelector(state => state.booking.branch)
    const selectDate = useSelector(state => state.booking.selectDate)
    const slots = useSelector(state => state.booking.slots)
    const typeExcursion = useSelector(state => state.booking.selectTypeExcursion)
    const calendar = useSelector(state => state.booking.calendar)
    const [objSelectDay, changeSelectDay] = useState(null)
    const formatDay = {weekday: 'short', month: 'long', day: 'numeric'};
    const [selectDateLabel, changeSelectDateLabel] = useState(null)
    const [branchName, changeBranchName] = useState(null)
    const [groupName, changeGroupName] = useState(null)

    useEffect(() => {
        const paramLang = params.lang
        const objLang = allLanguages.find(lang => lang.slug == paramLang)
        const defaultLang = allLanguages.find(lang => lang.default_language)
        if(objLang == undefined){
            navigate(`/${defaultLang.slug}/${params?.group}/booking`)
        }
    }, [allLanguages])
    useEffect(() => {
        if (selectDate) {
            changeSelectDay(new Date(selectDate.year, selectDate.month - 1, selectDate.day))
        } else {
            changeSelectDay(null)
        }
    }, [selectDate])
    useEffect(() => {
        if (objSelectDay && calendar && calendar.message != "Error") {

            let currentDay = calendar.find(item => item.day == selectDate.day && item.month == selectDate.month && item.year == selectDate.year)
            let slots = []

            if(currentDay && currentDay.time){
                currentDay.time.start.forEach((start, index) => {
                    let newSlot = {
                        id: index,
                        type: typeExcursion.key == "ticket" ? "ticket" : "group",
                        time: {
                            start: {
                                time: currentDay.time.start[index]
                            },
                            end: {
                                time: currentDay.time.end[index]
                            },
                        },
                        price: currentDay.time.costs[index],
                        available: currentDay.time.disabled[index]
                    }


                    if (newSlot.type == "group") {
                        newSlot.count = {
                            from: currentDay.time.tickets_min[index],
                            to: currentDay.time.tickets_max[index],
                        }
                    }
                    if (newSlot.type == "ticket") {
                        newSlot.tickets = currentDay.time.tickets[index]
                    }

                    slots.push(newSlot)

                })
            }
            dispatch(setSlots(slots))

            switch (params?.lang){
                case "ua":
                    changeSelectDateLabel(objSelectDay.toLocaleString('uk-UA', formatDay))
                    break;

                case "ru":
                    changeSelectDateLabel(objSelectDay.toLocaleString('ru-UA', formatDay))
                    break;

                case "en":
                    changeSelectDateLabel(objSelectDay.toLocaleString('en-UA', formatDay))
                    break;

                default:
                    changeSelectDateLabel(objSelectDay.toLocaleString('uk-UA', formatDay))
                    break
            }
        } else {
            dispatch(setSlots(null))
        }
    }, [objSelectDay])
    useEffect(() => {
        dispatch(clearTicketGroupData())
        dispatch(clearTickets())
        dispatch(setSelectSlot(null))
        dispatch(setSelectDate(null))
    }, [])
    useEffect(() => {
        dispatch(getExcursions({
            id: params.group
        }))
    }, [])
    useEffect(() => {
        if(branch){
            switch (params?.lang){
                case "ua":
                    changeBranchName(branch.name.find(lang => lang.id == 1).text)
                    break;

                case "ru":
                    changeBranchName(branch.name.find(lang => lang.id == 2).text)
                    break;

                case "en":
                    changeBranchName(branch.name.find(lang => lang.id == 3).text)
                    break;

                default:
                    changeBranchName(branch.name.find(lang => lang.default_language).text)
                    break
            }
        }
    }, [branch])
    useEffect(() => {
        if(group){
            switch (params?.lang){
                case "ua":
                    changeGroupName(group[0].name.find(item => item.id == 1).text)
                    break;

                case "ru":
                    changeGroupName(group[0].name.find(item => item.id == 2).text)
                    break;

                case "en":
                    changeGroupName(group[0].name.find(item => item.id == 3).text)
                    break;

                default:
                    changeGroupName(group[0].name.find(item => item.default_language).text)
                    break
            }
        }
    }, [group])

    if (group) {
        return (
            <div className={classes.container}>

                <header className={classes.header}>
                    {groupName &&
                        <h1>
                            <Translate text={'excursion_booking'}/> "{groupName}"
                        </h1>
                    }
                    {branchName &&
                        <p className={classes.location}>
                            <img src={location}/>
                            {branchName}
                        </p>
                    }
                    <p>
                        <Translate text={'pay_attention'}/>
                    </p>
                </header>

                <Tabs group={group}/>

                {typeExcursion &&
                    <div className={classes.content}>

                        <p>
                            <Translate text={'take_tours_in_2_groups'}/>
                        </p>

                        <div className={classes.columns}>

                            <Calendar/>

                            <div>

                                {objSelectDay &&
                                    <div>
                                        <div className={classes.header_slots}>
                                            {selectDateLabel &&
                                                <h2 style={{textTransform: "capitalize", marginTop: "0px",}}>
                                                    {selectDateLabel}
                                                </h2>
                                            }

                                            { typeExcursion && typeExcursion.key == "ticket" &&
                                                <h2 style={{marginTop: "0px", marginRight: "15px"}}>
                                                    <Translate text={"tickets_available_from"}/> {group.find(excursion => excursion.id == typeExcursion.id).baseTickets}
                                                </h2>
                                            }
                                        </div>

                                        <div className={classes.container_slots}>

                                            {slots &&
                                                slots.map(slot => {
                                                    return (
                                                        <Slot key={slot.time.start.time} slot={slot} typeExcursion={typeExcursion.key}/>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>

                                }

                            </div>

                        </div>

                    </div>
                }

            </div>
        );
    } else {
        return <></>
    }
};

export default Booking;