import React from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const Translate = ({text}) => {
    const params = useParams()
    const translates = useSelector(state => state.booking.translates)
    let translateText = translates[text]

    switch (params?.lang){
        case "ua":
            translateText = translateText.ua
            break;

        case "ru":
            translateText = translateText.ru
            break;

        case "en":
            translateText = translateText.en
            break;
        default:
            translateText = translateText.default
            break
    }

    return (
        <>
            {translateText}
        </>
    );
};

export default Translate;