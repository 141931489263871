import axios from "axios";

export default class BookingApi {

    static async getGroupExcursions(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/getexcursions', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async getCalendarMonth(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/getcalendarmonth', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async getDiscountsExcursion(id) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/getdiscounts', {
            token: localStorage.getItem('auth_token'),
            id
        })

        return response;
    }

    static async getOptionsExcursion(id) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/getoptions', {
            token: localStorage.getItem('auth_token'),
            id
        })

        return response;
    }

    static async getLanguagesExcursion(id) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/getlanguages', {
            token: localStorage.getItem('auth_token'),
            id
        })

        return response;
    }

    static async applyCertificate(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/checkcertificate', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async applyPromocode(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/checkpromocode', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async addBooking(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/addbooking', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async getTranslate(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/gettranslates', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async getAllLanguages(data) {
        const response = await axios.post('https://api.0300.com.ua/api/buybooking/getalllanguages', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }



}